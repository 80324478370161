*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins";
}

a{
    text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.greenGradient{
    background: linear-gradient(90deg, rgba(0,166,82,1) 5%, rgba(77,200,142,1) 100%);
}

.redGradient{
    background: linear-gradient(90deg, rgba(238,8,104,1) 5%, rgba(251,80,29,1) 100%);
}

.violetGradient{
    background: linear-gradient(90deg, rgba(233,81,118,1) 5%, rgba(181,30,161,1) 100%);
}

.lightGreenGradient{
    background: linear-gradient(90deg, rgba(71,164,21,1) 5%, rgba(146,208,77,1) 100%);
}

.orangeGradient{
    background: linear-gradient(90deg, rgba(185,56,11,1) 5%, rgba(215,145,6,1) 100%);
}

.blueGradient{
    background: linear-gradient(90deg, rgba(31,94,224,1) 5%, rgba(33,174,205,1) 100%);
}